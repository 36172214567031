import Background from '../../UI/Background';
import styles from './TerritoryContent.module.css';
import Title from '../../UI/Title';

import { useContext } from 'react';
import LanguageContext from '../../../context/language-context';

import CombinedMap from '../../../assets/maps/terkep.kml';
// import AllowedSpreaders from '../../../assets/maps/engedelyezett_szorok.kml';
// import CompanySpreaders from '../../../assets/maps/tarsasagi_szorok.kml';
// import WildLands from '../../../assets/maps/vadfoldek.kml';
// import Cuts from '../../../assets/maps/bevagasok.kml';
// import SmallGameTerritory from '../../../assets/maps/aprovad2023.kml';
import HighStands from '../../../assets/maps/lesek.kml';
import FilesList from '../../Files/FilesList';
import Image from '../../UI/Image';

const TerritoryContent = props => {
    const { language } = useContext(LanguageContext);

    const content = {
        hu: {
            title: 'Területünk',
            h2: 'Letölthető Google Earth projektek!',
            combinedMap: 'Kombinált térkép',
            zones: 'Körzetek',
            allowedSpreaders: 'Engedelyezett Szórók',
            highStands: 'Lesek',
            companySpreaders: 'Társasági Szórók',
            wildLands: 'Vadföldek',
            cuts: 'Bevágások',
            h2_2: 'Letölthető térképek',
            lowQuality: 'Térkép Csökkentett minőség',
            highQuality: 'Térkép Jó minőség',
            smallGame: 'Apróvadas területek'
        },
        en: {
            title: 'Territory',
            h2: 'Downloadable Google Earth projects!',
            combinedMap: 'Combined Map',
            zones: 'Zones',
            allowedSpreaders: 'Allowed Spreaders',
            highStands: 'High Stands',
            companySpreaders: 'Company Spreaders',
            wildLands: 'Wild Lands',
            cuts: 'Notches',
            h2_2: 'Downloadable Maps',
            lowQuality: 'Map Reduced quality',
            highQuality: 'Map Good quality',
            smallGame: 'Small Game'
        },
        de: {
            title: 'Territorium',
            h2: 'Herunterladbar Google Earth projekte!',
            combinedMap: 'Kombinierte Karte',
            zones: 'Zonen',
            allowedSpreaders: 'Zugelassene Kirrplätze',
            highStands: 'Jagdstände',
            companySpreaders: 'Jagdgenossenschaftliche Kirrplätze',
            wildLands: 'Wildflächen',
            cuts: 'Kerben',
            h2_2: 'Herunterladbare Karten',
            lowQuality: 'Karte Reduzierte Qualität',
            highQuality: 'Karte Gute Qualität',
            smallGame: 'Niederwild'
        }
    };

    const contentByLanguage = content[language];

    const filesList = [
        {
            href: CombinedMap,
            download: `${contentByLanguage.combinedMap}.kml`
        }
        //     href: HighStands,
        //     download: `${contentByLanguage.highStands}.kml`
        // },
        // {
        //     href: AllowedSpreaders,
        //     download: `${contentByLanguage.allowedSpreaders}.kml`
        // }, {
        //     href: CompanySpreaders,
        //     download: `${contentByLanguage.companySpreaders}.kml`
        // }, {
        //     href: WildLands,
        //     download: `${contentByLanguage.wildLands}.kml`
        // }, {
        //     href: Cuts,
        //     download: `${contentByLanguage.cuts}.kml`
        // }, {
        //     href: SmallGameTerritory,
        //     download: `${contentByLanguage.smallGame}.kml`
        // }
    ]

    const mapsList = [{
        href: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/Terkep02.pdf?alt=media&token=f504bc33-6439-47ed-afbf-88ef45edc30b',
        download: `${contentByLanguage.lowQuality}.pdf`
    }, {
        href: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/Terkep01.pdf?alt=media&token=55fce778-a08a-4f11-b07b-348045c6ec84',
        download: `${contentByLanguage.highQuality}.pdf`
    }];

    const mapUrl = 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/Terkep01.jpg?alt=media&token=5ec42225-68ee-43c1-b852-7451e0b8e8f3';

    return <Background>
        <div className={styles['territory-content']}>
            <Title title={contentByLanguage.title} />
            <div className={styles.image}>
                <a href={mapsList[1].href}>
                    <Image url={mapUrl} />
                </a>
            </div>
            <div className={styles['maps']}>
                <FilesList title={contentByLanguage.h2_2} files={mapsList} />
            </div>
            <div className={styles['map-projects']}>
                <FilesList title={contentByLanguage.h2} files={filesList} />
            </div>
        </div>
    </Background>
};

export default TerritoryContent;